import { z } from 'zod';

import { DocumentProcessingEventTypeSchema } from './documents';

export const DocumentProcessingEventPubsubEventSchema = z.object({
  documentId: z.string(),
  eventType: DocumentProcessingEventTypeSchema,
});

export type DocumentProcessingEventPubsubEvent = z.infer<
  typeof DocumentProcessingEventPubsubEventSchema
>;

export const ConversationUpdatedPubsubEventSchema = z.object({
  conversationId: z.string(),
});

export type ConversationUpdatedPubsubEvent = z.infer<
  typeof ConversationUpdatedPubsubEventSchema
>;

export const ChatCompletionChunkPubsubEventSchema = z.object({
  conversationId: z.string(),
  conversationMessageId: z.string(),
  content: z.string(),
  type: z.enum(['chunk', 'information']),
});

export type ChatCompletionChunkPubsubEvent = z.infer<
  typeof ChatCompletionChunkPubsubEventSchema
>;

export const SearchRequestUpdatedPubsubEventSchema = z.object({
  searchRequestId: z.string(),
  searchRequestEventId: z.string(),
});

export type SearchRequestUpdatedPubsubEvent = z.infer<
  typeof SearchRequestUpdatedPubsubEventSchema
>;

export const AnalysisRequestRunEventPubsubEventSchema = z.object({
  analysisRequestRunId: z.string(),
});

export type AnalysisRequestRunEventPubsubEvent = z.infer<
  typeof AnalysisRequestRunEventPubsubEventSchema
>;

export const InsightRequestRunEventPubsubEventSchema = z.object({
  insightRequestRunId: z.string(),
  label: z.string(),
  progress: z.number().min(0).max(100),
});

export type InsightRequestRunEventPubsubEvent = z.infer<
  typeof InsightRequestRunEventPubsubEventSchema
>;
