import { Documents } from '@/entities/Documents';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import FileWordOutlined from '@ant-design/icons/FileWordOutlined';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Input, { type InputRef } from 'antd/es/input/Input';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { type ColumnType } from 'antd/es/table';
import Tag from 'antd/es/tag';
import theme from 'antd/es/theme';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import mime from 'mime';
import { useMemo, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  type DocumentDetail,
  type ListDocumentsQuery,
  type ListDocumentsResponse,
} from '@mai/types';

import ContentContainer from '../ContentContainer';
import Table from '../Table';
import TableHeader from '../TableHeader';

import { useFetchDocumentsQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { useTeamIsActive } from '@utils/billing';
import { renderDate } from '@utils/dates';
import { useTeamId } from '@utils/router';

const SHOW_ARCHIVED_PARAM = 'showArchived';

const DocumentsProcessingStatus = ({
  filters,
}: {
  filters: ListDocumentsQuery;
}) => {
  const documentsPendingProcessingQuery = useFetchDocumentsQuery({
    ...filters,
    isProcessed: false,
    pageSize: 1,
  });
  const documentsPendingProcessingCount =
    documentsPendingProcessingQuery.data?.count;

  if (documentsPendingProcessingQuery.isLoading) {
    return <Skeleton.Input size="small" style={{ width: 180 }} active />;
  }

  return (
    <Tag color={documentsPendingProcessingCount ? 'blue' : 'green'}>
      <Typography.Text
        type="secondary"
        style={{
          display: 'flex',
          gap: '0.25rem',
        }}
      >
        {documentsPendingProcessingCount ? (
          <Tooltip title="These documents won't be available for search, analysis or assistant interaction until they finish processing.">
            {documentsPendingProcessingCount} Documents Processing{' '}
            <SyncOutlined spin />
          </Tooltip>
        ) : (
          <>
            All Documents Processed
            <CheckCircleOutlined />
          </>
        )}
      </Typography.Text>
    </Tag>
  );
};

const DocumentsTableHeader = ({
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  setIsSubmitting,
  pageSize,
  page,
  teamIds,
  projectIds,
  isLoading,
}: {
  dataSource: ListDocumentsResponse;
  selectedRowKeys: React.Key[];
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSelectedRowKeys: (selectedRowKeys: React.Key[]) => void;
  pageSize: string;
  page: number;
  teamIds?: string[];
  projectIds?: string[];
  isLoading: boolean;
}) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { modal } = App.useApp();
  const teamIsActive = useTeamIsActive();

  return (
    <TableHeader>
      <div
        style={{
          width: '100%',
        }}
      >
        {selectedRowKeys.length > 0 && (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <Button
              disabled={selectedRowKeys.length === 0}
              size="small"
              danger
              onClick={() => {
                return modal.confirm({
                  maskClosable: true,
                  title: 'Delete Documents',
                  content: `Are you sure you want to delete ${selectedRowKeys.length} documents? This action is not reversible.`,
                  closable: true,
                  icon: null,
                  okButtonProps: {
                    size: 'small',
                    danger: true,
                  },
                  cancelButtonProps: {
                    size: 'small',
                  },
                  onOk: async () => {
                    setIsSubmitting(true);
                    await Documents.deleteMany(selectedRowKeys as string[], {
                      onSuccess: () => setSelectedRowKeys([]),
                      onFinish: () => setIsSubmitting(false),
                    });
                  },
                });
              }}
            >
              Delete Selected
            </Button>
            <Button
              disabled={selectedRowKeys.length === 0 || !teamIsActive}
              size="small"
              onClick={() => {
                return modal.confirm({
                  maskClosable: true,
                  title: 'Re-Sync Documents',
                  content: `Are you sure you want to re-sync ${selectedRowKeys.length} documents?`,
                  closable: true,
                  icon: null,
                  okButtonProps: {
                    size: 'small',
                  },
                  cancelButtonProps: {
                    size: 'small',
                  },
                  onOk: async () => {
                    setIsSubmitting(true);
                    await Documents.resyncMany(selectedRowKeys as string[], {
                      onSuccess: () => setSelectedRowKeys([]),
                      onFinish: () => setIsSubmitting(false),
                    });
                  },
                });
              }}
            >
              Re-Sync Selected
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <DocumentsProcessingStatus
          filters={{
            teamIds,
            projectIds,
            title: searchParams.get('title') ?? undefined,
            isProcessed: false,
            pageSize: 1,
          }}
        />
        <Select
          options={[
            { label: '10', value: '10' },
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
          size="small"
          value={pageSize}
          suffixIcon={<>/ Page</>}
          onChange={(value) => {
            setSearchParams((prev) => {
              prev.set('pageSize', value);
              return prev;
            });
          }}
          style={{
            width: '5rem',
          }}
        />
        <Tooltip title="Previous Page">
          <Button
            size="small"
            disabled={page === 1}
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page - 1).toString());
                return prev;
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Next Page">
          <Button
            size="small"
            disabled={dataSource.length < parseInt(pageSize ?? '10', 10)}
            icon={<ArrowRightOutlined />}
            onClick={() => {
              setSearchParams((prev) => {
                prev.set('page', (page + 1).toString());
                return prev;
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Refresh">
          <Button
            size="small"
            icon={<SyncOutlined spin={isLoading} />}
            onClick={() => {
              void queryClient.invalidateQueries({
                queryKey: ['documents'],
              });
            }}
          />
        </Tooltip>
      </div>
    </TableHeader>
  );
};

const DocumentsTable = ({
  teamIds,
  projectIds,
  size,
  showHeader = true,
}: {
  teamIds?: string[];
  projectIds?: string[];
  size?: 'small' | 'middle' | 'large';
  showHeader?: boolean;
}) => {
  const { token } = theme.useToken();
  const { message } = App.useApp();
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInput = useRef<InputRef>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const pageSize = searchParams.get('pageSize') ?? '50';
  const page = parseInt(searchParams.get('page') ?? '1', 10);

  const documentsQuery = useFetchDocumentsQuery({
    projectIds,
    teamIds,
    title: searchParams.get('title') ?? undefined,
    isArchived: searchParams.get(SHOW_ARCHIVED_PARAM) ? undefined : false,
    pageSize: parseInt(pageSize ?? '10', 10),
    page,
  });

  const onConfirmDelete = async () => {
    if (!documentToDelete) return;
    setIsSubmitting(true);
    await Documents.delete(documentToDelete, {
      onSuccess: () => {
        setDocumentToDelete(null);
      },
      onFinish: () => {
        setIsSubmitting(false);
      },
    });
  };

  const columns: ColumnType<ListDocumentsResponse[0]>[] = useMemo(() => {
    const handleReset = (clearFilters?: () => void) => {
      if (clearFilters) clearFilters();
      setSearchParams((prev) => {
        prev.delete('title');
        return prev;
      });
    };

    const onSearchTitle = (value?: string) => {
      setSearchParams((prev) => {
        if (value) {
          prev.set('title', value);
        } else {
          prev.delete('title');
        }
        return prev;
      });
    };

    return [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
        align: 'left',
        ellipsis: true,
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        filterDropdown: ({ clearFilters }) => {
          return (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
              <Input
                ref={searchInput}
                placeholder={`Search title`}
                onPressEnter={() => {
                  onSearchTitle(searchInput.current?.input?.value);
                }}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    onSearchTitle(searchInput.current?.input?.value);
                  }}
                  icon={<FilterOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Filter
                </Button>
                <Button
                  onClick={() => {
                    handleReset(clearFilters);
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </Space>
            </div>
          );
        },
        render: (value, record) => {
          return (
            <Link to={`/team/${teamId}/documents/${record.id}`}>{value}</Link>
          );
        },
      },
      {
        title: 'File Type',
        width: 100,
        dataIndex: 'fileType',
        key: 'fileType',
        align: 'center',
        render: (value) => {
          const extension = mime.getExtension(value);
          if (extension === 'pdf') {
            return (
              <Tooltip title="PDF Document">
                <FilePdfOutlined />
              </Tooltip>
            );
          }
          if (extension === 'docx') {
            return (
              <Tooltip title="Word Document">
                <FileWordOutlined />
              </Tooltip>
            );
          }
          if (extension === 'txt') {
            return (
              <Tooltip title="Plain Text Document">
                <FileTextOutlined />{' '}
              </Tooltip>
            );
          }
          return extension ? extension.toUpperCase() : value;
        },
      },
      {
        title: 'Status',
        width: 100,
        dataIndex: 'hasProcessed',
        key: 'hasProcessed',
        align: 'center',
        render: (value) =>
          value ? (
            <CheckCircleOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          ) : (
            <SyncOutlined
              spin
              style={{
                color: token.colorPrimary,
              }}
            />
          ),
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: (createdAt) => renderDate(createdAt),
      },
      {
        key: 'actions',
        align: 'right',
        fixed: 'right',
        width: 50,
        render: (_, record) => {
          return (
            <Dropdown
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: 'view',
                    icon: <EyeOutlined />,
                    label: (
                      <Link to={`/team/${teamId}/documents/${record.id}`}>
                        View
                      </Link>
                    ),
                  },
                  {
                    key: 're-sync',
                    disabled: !teamIsActive,
                    icon: <SyncOutlined />,
                    label: 'Re-sync',
                    onClick: async () => {
                      await Documents.resync(record.id);
                    },
                  },
                  {
                    key: 'download',
                    label: <div>Download</div>,
                    onClick: async () => {
                      const loadingMessage = message.loading(
                        'Starting Download...',
                      );
                      try {
                        const { data } = await apiClient.get<DocumentDetail>(
                          `/documents/${record.id}`,
                        );
                        const downloadUrl = data.downloadUrl;
                        window.open(downloadUrl);
                      } finally {
                        loadingMessage();
                      }
                    },
                    icon: <DownloadOutlined />,
                  },
                  {
                    key: 'delete',
                    label: 'Delete',
                    danger: true,
                    icon: <DeleteOutlined />,
                    onClick: async () => {
                      setDocumentToDelete(record.id);
                    },
                  },
                ],
              }}
              placement="bottomRight"
            >
              <Button icon={<MoreOutlined />}></Button>
            </Dropdown>
          );
        },
      },
    ];
  }, [setSearchParams, teamId, token.colorPrimary, teamIsActive, message]);

  const dataSource = documentsQuery.data?.data ?? [];

  if (documentsQuery.isError) {
    <ContentContainer.Error />;
  }

  return (
    <>
      <Modal
        title="Delete Document"
        open={documentToDelete !== null}
        onOk={onConfirmDelete}
        okText="Delete"
        okType="danger"
        onCancel={() => setDocumentToDelete(null)}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to delete this document?
      </Modal>
      {showHeader && (
        <DocumentsTableHeader
          dataSource={dataSource}
          selectedRowKeys={selectedRowKeys}
          setIsSubmitting={setIsSubmitting}
          setSelectedRowKeys={setSelectedRowKeys}
          pageSize={pageSize}
          page={page}
          teamIds={teamIds}
          projectIds={projectIds}
          isLoading={documentsQuery.isLoading}
        />
      )}
      <Table
        columns={columns}
        loading={documentsQuery.isLoading}
        dataSource={dataSource}
        size={size ?? 'middle'}
        rowKey="id"
        pagination={false}
        rowSelection={{
          columnWidth: 40,
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
      />
    </>
  );
};

export default DocumentsTable;
