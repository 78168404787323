import { z } from 'zod';

import { DocumentProcessingEventTypeSchema } from './documents';

const SubscribeOptionsSchema = z.union([
  z.object({
    channel: z.literal('conversation_updated'),
    conversationId: z.string(),
  }),
  z.object({
    channel: z.literal('search_request_updated'),
    searchRequestId: z.string(),
  }),
  z.object({
    channel: z.literal('chat_completion_chunk'),
    conversationId: z.string(),
  }),
  z.object({
    channel: z.literal('analysis_request_run_event'),
    analysisRequestRunId: z.string(),
  }),
  z.object({
    channel: z.literal('insight_request_run_event'),
    insightRequestRunId: z.string(),
  }),
  z.object({
    channel: z.literal('document_processing_event'),
    documentId: z.string(),
  }),
]);

const UnsubscribeOptionsSchema = z.union([
  z.object({
    channel: z.literal('conversation_updated'),
    conversationId: z.string(),
  }),
  z.object({
    channel: z.literal('search_request_updated'),
    searchRequestId: z.string(),
  }),
  z.object({
    channel: z.literal('chat_completion_chunk'),
    conversationId: z.string(),
  }),
  z.object({
    channel: z.literal('analysis_request_run_event'),
    analysisRequestRunId: z.string(),
  }),
  z.object({
    channel: z.literal('insight_request_run_event'),
    insightRequestRunId: z.string(),
  }),
  z.object({
    channel: z.literal('document_processing_event'),
    documentId: z.string(),
  }),
]);

export const ClientWebSocketMessageSchema = z.object({
  payload: z.union([
    z.object({
      type: z.literal('subscribe'),
      options: SubscribeOptionsSchema,
    }),
    z.object({
      type: z.literal('unsubscribe'),
      options: UnsubscribeOptionsSchema,
    }),
  ]),
  token: z.string(),
});

export type ClientWebSocketMessage = z.infer<
  typeof ClientWebSocketMessageSchema
>;

export const ServerWebSocketMessageSchema = z.object({
  payload: z.union([
    z.object({
      type: z.literal('conversation_updated'),
      conversationId: z.string(),
    }),
    z.object({
      type: z.literal('search_request_updated'),
      searchRequestId: z.string(),
    }),
    z.object({
      type: z.literal('chat_completion_chunk'),
      conversationId: z.string(),
      conversationMessageId: z.string(),
      content: z.string(),
      contentType: z.enum(['chunk', 'information']),
    }),
    z.object({
      type: z.literal('analysis_request_run_event'),
      analysisRequestRunId: z.string(),
    }),
    z.object({
      type: z.literal('insight_request_run_event'),
      insightRequestRunId: z.string(),
      label: z.string(),
      progress: z.number().min(0).max(100),
    }),
    z.object({
      type: z.literal('document_processing_event'),
      documentId: z.string(),
      eventType: DocumentProcessingEventTypeSchema,
    }),
  ]),
});

export type ServerWebSocketMessage = z.infer<
  typeof ServerWebSocketMessageSchema
>;
