import theme from 'antd/es/theme';
import { ThemeProvider } from 'styled-components';

const StyledComponentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { token } = theme.useToken();
  return <ThemeProvider theme={{ antd: token }}>{children}</ThemeProvider>;
};

export default StyledComponentProvider;
