import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';
import uniqBy from 'lodash.uniqby';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useFetchDocumentsQuery } from '@queries/documents';

const DocumentSelect = ({
  teamIds,
  projectIds,
  documentIds,
  allowWildcard = false,
  value,
  onChange,
  size = 'middle',
  style,
}: {
  teamIds?: string[];
  projectIds?: string[];
  documentIds?: string[];
  allowWildcard?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  size?: 'large' | 'middle' | 'small';
  style?: React.CSSProperties;
}) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  const queryEnabled = allowWildcard
    ? true
    : !!teamIds?.length || !!projectIds?.length || !!documentIds?.length;
  const documentsQuery = useFetchDocumentsQuery(
    {
      teamIds,
      projectIds,
      documentIds,
      title: debouncedSearch ? debouncedSearch : undefined,
    },
    queryEnabled,
  );

  const selectedDocumentsQuery = useFetchDocumentsQuery(
    {
      documentIds: value,
    },
    (value?.length ?? 0) > 0,
  );

  const documents = useMemo(() => {
    return uniqBy(
      [
        ...(documentsQuery.data?.data ?? []),
        ...(selectedDocumentsQuery.data?.data ?? []),
      ],
      (document) => document.id,
    );
  }, [documentsQuery.data?.data, selectedDocumentsQuery.data?.data]);

  return (
    <Select
      allowClear
      placeholder="Select a document"
      size={size}
      options={documents.map((document) => ({
        value: document.id,
        label: document.title,
      }))}
      mode="multiple"
      maxTagCount={1}
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <Tag closable={closable} onClose={onClose} color="purple">
            <Typography.Text
              ellipsis
              style={{ maxWidth: '120px', fontSize: '0.7rem' }}
            >
              {label}
            </Typography.Text>
          </Tag>
        );
      }}
      loading={documentsQuery.isLoading || debouncedSearch !== search}
      onSearch={setSearch}
      filterOption={false}
      style={{ width: '100%', ...style }}
      value={value}
      onChange={onChange}
    />
  );
};

export default DocumentSelect;
