import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

import { forgivingNanoidArrayRegex } from '@mai/types';

import { useEmbeddingsQuery } from '@queries/embeddings';

const MarkdownContainer = styled.div`
  .markdown p {
    margin: 0.25rem 0;
  }
`;

const MarkdownContent = ({ content }: { content: string }) => {
  // Replace inline references with links to the
  const { formattedContent, embeddingIds } = useMemo(() => {
    // Parse references from the inline citations
    const nanoidRegex = /[A-Za-z0-9_-]{21}/g;

    // Match all with the forgiving regex to find the start and end offsets
    const matches = Array.from(content.matchAll(forgivingNanoidArrayRegex));
    let formattedContent = content;
    const embeddingIdToIndex: Record<string, number> = {};
    for (const match of matches) {
      const embeddingIds = Array.from(match[0]!.matchAll(nanoidRegex))
        .map((embeddingId) => embeddingId[0])
        .filter(Boolean);
      for (const embeddingId of embeddingIds) {
        if (!(embeddingId in embeddingIdToIndex)) {
          embeddingIdToIndex[embeddingId] =
            Object.keys(embeddingIdToIndex).length + 1;
        }
      }
      const markdownLinks = embeddingIds.map(
        (embeddingId) =>
          `[[${embeddingIdToIndex[embeddingId]}]](/embeddings/${embeddingId})`,
      );
      formattedContent = formattedContent.replace(
        match[0]!,
        markdownLinks.join(' '),
      );
    }

    return { formattedContent, embeddingIds: Object.keys(embeddingIdToIndex) };
  }, [content]);

  const embeddingsQuery = useEmbeddingsQuery({
    embeddingIds,
  });

  return (
    <div>
      <MarkdownContainer>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({ node: _, ...props }) => (
              <p
                {...props}
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
              />
            ),
            a: ({ node: _, ...props }) => {
              // Use react router link if relative link
              if (props.href?.includes('/embeddings/')) {
                const embeddingId = props.href?.split('/embeddings/')[1];
                const embedding = embeddingsQuery.data?.find(
                  (embedding) => embedding.id === embeddingId,
                );
                return (
                  <Tooltip
                    title={
                      <div
                        style={{
                          maxWidth: '300px',
                          maxHeight: '300px',
                          overflow: 'scroll',
                        }}
                      >
                        {embedding?.content}
                      </div>
                    }
                  >
                    <Typography.Link
                      style={{
                        cursor: 'default',
                      }}
                    >
                      {props.children}
                    </Typography.Link>
                  </Tooltip>
                );
              }
              // Use normal anchor tag for external links
              return <a href={props.href}>{props.children}</a>;
            },
          }}
        >
          {formattedContent}
        </ReactMarkdown>
      </MarkdownContainer>
    </div>
  );
};

export default MarkdownContent;
